<!--
 * @Descripttion: 全景列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-15 16:16:34
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-08-23 10:19:38
-->
<template>
    <div class="scenariosList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">全景列表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <div class="sw-left">
                    <div class="search-item">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="sw-right">
                    <el-button class="btns" @click="isAddScenarios = true" type="primary">添加全景</el-button>
                </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column align="center" prop="id" label="ID" width="60"></el-table-column>
                    <el-table-column align="center" prop="name" label="全景名称" ></el-table-column>
                    <el-table-column align="center" prop="cover" label="封面图片" width="80">
                        <template slot-scope="scope">
                            <el-image :src="scope.row.cover.split(',')[0]" :preview-src-list="scope.row.cover.split(',')" height="30" alt=""></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="design_building.name" label="小区名称" ></el-table-column>
                    <el-table-column align="center" prop="design_room.name" label="户型名称" ></el-table-column>
                    <el-table-column align="center" prop="design_room.framework" label="户型结构" width="100"></el-table-column>
                    <el-table-column align="center" prop="use_good_names" label="产品运用" ></el-table-column>
                    <el-table-column align="center" prop="link" label="vr链接" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                             <el-link :href="scope.row.link" target="_blank">{{scope.row.link}}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="view_count" label="浏览次数" width="80"></el-table-column>
                    <el-table-column align="center" prop="share_count" label="转发次数" width="80"></el-table-column>
                    <el-table-column align="center" prop="like_count" label="收藏次数" width="80"></el-table-column>
                    <el-table-column align="center" prop="create_user.username" label="创建人" width="100"></el-table-column>
                    <el-table-column align="center" fixed="right" label="操作" width="90">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                    <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="clickDelete(scope.row)" class="iconfont el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :before-close="handleCloseScenarios" 
            :close-on-click-modal="false"
            :visible.sync="isAddScenarios" 
            custom-class="dialog-wrap">
            <div v-if="editId<0" class="title">添加全景素材</div>
            <div v-else class="title">编辑全景素材</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="scenariosForm" :rules="rulesScenarios" ref="scenariosForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="选择小区" prop="building_id">
                        <el-select v-model="scenariosForm.building_id" 
                            placeholder="请选择小区" @change="selectChangebui">
                            <el-option
                                v-for="item in buiOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择户型" prop="room_id">
                        <el-select clearable v-model="scenariosForm.room_id" placeholder="请选择户型">
                            <el-option
                                v-for="item in roomOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择风格" prop="style_id">
                        <el-select clearable v-model="scenariosForm.style_id" placeholder="请选择风格">
                            <el-option
                                v-for="item in styleOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="全景名称" prop="name">
                        <el-input v-model="scenariosForm.name" placeholder="请输入全景名称"></el-input>
                    </el-form-item>
                    <el-form-item label="全景简介" prop="synopsis">
                        <el-input v-model="scenariosForm.synopsis" placeholder="请输入全景简介"></el-input>
                    </el-form-item>
                    <el-form-item label="预算金额" prop="budget">
                        <el-input v-model.number="scenariosForm.budget" placeholder="请输入预算(万元)"></el-input>
                    </el-form-item>
                    <el-form-item label="全景链接" prop="link">
                        <el-input v-model="scenariosForm.link" placeholder="请输入全景链接"></el-input>
                    </el-form-item>
                    <el-form-item label="产品运用" prop="use_good">
                        <el-select filterable clearable multiple :multiple-limit="3" 
                            v-model="scenariosForm.use_good" placeholder="请选择产品">
                            <el-option
                                v-for="item in goodOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="封面图片" prop="cover">
                        <div class="upload-wrap">
                          <draggable v-model="image" class="fileList-box">
                            <div v-for="(item,i1) of image" :key="i1" class="fileList1-box">
                              <div @click="delImageList(item,i1)" class="delFile">x</div>
                              <el-image
                                  fit="contain"
                                  class="pic"
                                  :preview-src-list="image"
                                  :src="item"
                              />
                            </div>
                          </draggable>

                          <aliyun-upload
                              :multiple="true"
                              :limitSize="8" :limit="5" :isShopTip="false"
                              :btnDisplay="image.length<5"
                              v-model="image">
                          </aliyun-upload>
                        </div>
                    </el-form-item>
                    <el-form-item label="详情图片" prop="details_img">
                        <div class="upload-wrap">
                            <draggable v-model="image2" class="fileList-box">
                              <div v-for="(item,i1) of image2" :key="i1" class="fileList1-box">
                                <div @click="delImageList2(item,i1)" class="delFile">x</div>
                                <el-image
                                    fit="contain"
                                    class="pic"
                                    :preview-src-list="image2"
                                    :src="item"
                                />
                              </div>
                            </draggable>

                            <aliyun-upload
                                :multiple="true"
                                :limitSize="8" :limit="10" :isShopTip="false"
                                :btnDisplay="image2.length<10"
                                v-model="image2">
                            </aliyun-upload>
                        </div>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button v-if="editId<0" :loading="btnLoading" type="primary" @click="submitForm">立即创建</el-button>
                        <el-button v-else :loading="btnLoading" type="primary" @click="submitFormEdit">编 辑</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import draggable from "vuedraggable";
export default {
    components:{
        ElImageViewer,
        AliyunUpload,
        draggable,
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'scenariosList',
            funClassif: '',
            styleClassif: '',
            exclusiveStores: '',
            keyword: '',
            funOptions: [],
            styleOptions: [],
            buiOptions: [],
            roomOptions: [],
            goodOptions: [],
            currentPage: 1,
            total: 0,
            tableData: [],
            loading: false,
            addLoading: false,
            btnLoading: false,
            isAddScenarios: false,
            scenariosForm: {
                building_id: '',
                room_id: '',
                style_id: '',
                name: '',
                synopsis: '',
                budget: '',
                link: '',
                use_good: [],
                cover: '',
                details_img: '',
            },
            rulesScenarios: {
                building_id: [
                    { required: true, message: '请选择小区', trigger: 'change' },
                ],
                room_id: [
                    { required: true, message: '请选择户型', trigger: 'change' },
                ],
                style_id: [
                    { required: true, message: '请选择风格', trigger: 'change' },
                ],
                name: [
                    { required: true, message: '请输入全景简介', trigger: 'blur' },
                ],
                synopsis: [
                    { required: true, message: '请输入预算金额', trigger: 'blur' },
                ],
                budget: [
                    { required: true, message: '请输入全景名称', trigger: 'blur' },
                ],
                link: [
                    { required: true, message: '请输入全景链接', trigger: 'blur' },
                ],
                cover: [
                    { required: true, message: '请上传封面图片', trigger: 'blur' },
                ],
                details_img: [
                    { required: true, message: '请上传详情图片', trigger: 'blur' },
                ],
                use_good: [
                    { required: true, message: '请选择产品', trigger: 'change' },
                ],
            },
            image: [],
            image2: [],
            editId: -1
        }
    },
    watch:{
        image(newVal,oldVal){
            this.scenariosForm.cover = newVal
        },
        image2(newVal,oldVal){
            this.scenariosForm.details_img = newVal
        },
        isAddScenarios(newVal,oldVal){
            if(!newVal){
                let scenariosForm = {
                    building_id: '',
                    room_id: '',
                    style_id: '',
                    name: '',
                    synopsis: '',
                    budget: '',
                    link: '',
                    use_good: [],
                    cover: '',
                    details_img: '',
                };
                this.image = [];
                this.image2 = [];
                this.editId = -1;
                this.scenariosForm = scenariosForm
            }
        }
    },
    mounted () {
        this.getSceneList();
        this.getBuildingList();
        this.getGoodsList();
        this.getStyleList();
    },
    methods: {
        handleRemove(file, fileList) {
          console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
        },

        // 选择户型
        selectChangebui(){
            this.scenariosForm.room_id = '';
            let params = {
                building_id: this.scenariosForm.building_id
            }
            common.connect('/distributorpcv1/scene_case/buildingRoomConditions',params,(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.id,
                    label: v.name
                }
                })
                this.roomOptions = resultSecond;
            })
        },
        // 获取数据列表
        getSceneList(){
            let params = {
                page: this.currentPage
            }
            this.loading = true;
            common.connect('/distributorpcv1/scene_case/index',params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
         // 获取小区数据列表
        getBuildingList(){
            common.connect('/distributorpcv1/scene_case/buildingConditions',{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.id,
                    label: v.name
                }
                })
                this.buiOptions = resultSecond;
            })
        },
         // 获取风格数据列表
        getStyleList(){
            common.connect('/distributorpcv1/scene_case/sceneStyle',{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.id,
                    label: v.name
                }
                })
                this.styleOptions = resultSecond;
            })
        },
         // 获取产品数据列表
        getGoodsList(){
            common.connect('/distributorpcv1/shop/goodsConditions',{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.id,
                    label: v.model
                }
                })
                this.goodOptions = resultSecond;
            })
        },
        // 创建
        submitForm(){
            this.$refs.scenariosForm.validate((valid) => {
                if (valid) {
                    let names = [];
                    this.goodOptions.forEach(v=>{
                        this.scenariosForm.use_good.forEach(v1=>{
                            if(v.value == v1){
                                names.push(v.label)
                            }
                        })
                    })
                    this.scenariosForm.use_good_ids = '['+this.scenariosForm.use_good+']';
                    this.scenariosForm.use_good_names = '['+names+']';
                    this.btnLoading = true;
                    common.connect('/distributorpcv1/scene_case/store',this.scenariosForm,(res)=>{
                        if(res.isSuccess == 1){
                            this.getSceneList();
                            this.isAddScenarios = false;
                            common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC-创建全景'})
                        }
                        this.btnLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑更新
        submitFormEdit(){
            this.$refs.scenariosForm.validate((valid) => {
                if (valid) {
                    let names = [];
                    this.goodOptions.forEach(v=>{
                        this.scenariosForm.use_good.forEach(v1=>{
                            if(v.value == v1){
                                names.push(v.label)
                            }
                        })
                    })
                    this.scenariosForm.use_good_ids = '['+this.scenariosForm.use_good+']';
                    this.scenariosForm.use_good_names = '['+names+']';
                    this.scenariosForm.id = this.editId;
                    this.btnLoading = true;
                    common.connect('/distributorpcv1/scene_case/update',this.scenariosForm,(res)=>{
                        if(res.isSuccess == 1){
                            this.getSceneList();
                            this.isAddScenarios = false;
                            common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC-编辑全景'})
                        }
                        this.btnLoading = false;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑页
        clickEdit(row){
            this.editId = row.id;
            common.connect('/distributorpcv1/scene_case/edit',{id: row.id},(res)=>{
                this.isAddScenarios = true;
                this.scenariosForm.building_id = res.data.building_id;
                this.scenariosForm.room_id = res.data.room_id;
                this.scenariosForm.style_id = res.data.style_id;
                this.scenariosForm.name = res.data.name;
                this.scenariosForm.synopsis = res.data.synopsis;
                this.scenariosForm.budget = Number(res.data.budget);
                this.scenariosForm.link = res.data.link;
                this.scenariosForm.cover = res.data.cover;
                this.scenariosForm.details_img = res.data.details_img;
                this.image = res.data.cover;
                this.image2 = res.data.details_img;
                this.scenariosForm.use_good = JSON.parse(res.data.use_good_ids);
            })
        },
        // 删除
        clickDelete(row){
            this.$confirm('此操作将永久删除该案例, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/distributorpcv1/scene_case/delete',{id: row.id},(res)=>{
                    this.getSceneList();
                    common.connect('/distributorpcv1/log/storeLogShopFunction',{function: 'PC-删除全景'})
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 关键词搜索
        clickSearch(){
            this.getSceneList();
        },
        delImageList(item, i){
            this.image.splice(i, 1);
        },
        delImageList2(item, i){
            this.image2.splice(i, 1);
        },
        // 关闭弹窗
        handleCloseScenarios(){
            this.isAddScenarios = false;
        },
        handleCurrentChange(){},
    },
}
</script>

<style lang='scss'>
.fileList-box {
  display: flex;

  .fileList1-box {
    position: relative;

    .pic {
      width: 100px !important;
      height: 100px !important;
    }
  }

  .fileList1-box + .fileList1-box{
    margin-left: 5px;
  }
}

.scenariosList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .screen-wrap{
            display: flex;
            justify-content: space-between;
            .sw-left{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .screen-item{
                    margin-right: 15px;
                    margin-bottom: 20px;
                }
                .search-item{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-bottom: 20px;
                    .el-input{
                        width: 220px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .table-wrap{
            .image{
                width: 40px;
                cursor: pointer;
            }
            .operation-wrap{
                display: flex;
                justify-content: center;
                .iconfont{
                    font-size: 16px;
                    padding: 0 10px;
                    cursor: pointer;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
    .dialog-wrap{
        width: 750px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-top: 20px;
            .el-input{
                width: 630px
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
            .upload-wrap-content{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .upload-wrap{
                padding-top: 2px;
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
      
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .el-checkbox-group{
        .el-checkbox{
            margin-right: 0 !important;
        }
    }
}
</style>
